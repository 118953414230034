import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ArticlePage = ({location}) =>
  (
    <Layout>

      <SEO title="How to take incredible photos of your prestige car before sale"
        description="Our downloadable PDF guide will step you through how to take high quality photos of your car, to help you to find the right high quality buyer. "
        location={location}
      />

      <PageHeader
        title="How to take incredible photos of your prestige car before sale"
        text="Our downloadable PDF guide will step you through how to take high quality photos of your car, to help you to find the right high quality buyer. "
      />

      <SectionPageMargin>

          <Heading2>Summon Car Photography Guide</Heading2>

          <Paragraph1>
          If there is one thing that drives me bonkers, it’s seeing a beautiful classic or modern prestige car listed for sale, with awful photos.
          </Paragraph1>

          <Paragraph1>
          You know the one, when the car hasn’t even been driven out of the garage, or photographed in the local Coles carpark, or with dirty footwells… the list goes on.
          </Paragraph1>

          <Paragraph1>
           If you are selling your car privately, please go to the effort of taking great photos (or even hiring a professional photographer), you will be rewarded with higher quality enquiries, and probably a higher sale price. 
          </Paragraph1>

          <Paragraph1>
          With high quality photos the perceived quality of your car will be higher, and potential buyers will assume the car has been well cared for.
          </Paragraph1>

          <Paragraph1>
          If you don’t have time to take great photos, you should hire Summon to advertise your car for you - request a <Link to="https://value.summon.com.au//">free valuation here</Link>. 
          </Paragraph1>

          <Paragraph1>
            If you do have the time and the inclination, download our free guide now for advice on how to make your photos great: <a href="https://assets.ctfassets.net/4503lf2fbhu7/1GIDCXoyMfbiw1Uzh4Ajz6/fe10bd2c2e241ffa13ca844c473f50cf/Summon_-_photo_guide.pdf"  rel="noopener noreferrer nofollow" 
   target="_blank">Guide to taking incredible photos of your prestige car</a>
          </Paragraph1>


      </SectionPageMargin>


     <ReadyToGetStarted />

    </Layout>

  )

export default ArticlePage
